<template>
  <div>
    <div class="paysuccess_wrap">
      <div class="paysuccess_con">
        <h5>
          <i><img src="@/assets/images/common/pay_success.png" alt=""></i>
          {{this.$route.query.payType == 1 ? '支付成功' : '试用服务已开通'}}
        </h5>

        <p v-if="td != -11 || td != '-11'">
          <span class="active" @click="serverEvent(true)">现在开启服务</span>
          <span  @click="serverEvent(false)">稍后手动开启</span>
        </p>

        <p v-else>
          <span class="active" @click="orderEvent">我的订单</span>
        </p>
      </div>
    </div>
    <footers></footers>
  </div>
</template>
<script>
import footers from '@/components/common/xfooter'
export default {
  name: "paySuccess",
  data () {
    return {
      td: '',
      timer: null
    }
  },
  components: { footers },
  mounted() {
    this.td = this.$route.query.td
    this.payStatus()
    this.timer = setInterval(() => {
      this.payStatus()
    },1000)
  },
  destroyed () {
    clearInterval(this.timer);
  },
  methods: {
    /*查看是否支付成功*/
    async payStatus () {
      let data = await this.$axios.get(`/order/order/${localStorage.getItem('orderId')}`);
      if (data.data.code == 0) {
        if (data.data.data.isPaid == 'y' && data.data.data.orderStatus == 20) {
          clearInterval(this.timer);
          if(localStorage.getItem('MT')){
            this.$router.push({
              name: "TM",
              params: {
                flag: 1,
                id: this.$route.query.td
              }
            })
          }
        }
      }
    },
    serverEvent (item) {
      this.$router.push({
        path: "/personalCenter/myServer",
        query:{
          open: item ? item : false,
          td: item ? this.td :undefined,
          try: this.$route.query.try ? '28' : "27"
        }
      })
    },
    orderEvent () {

      this.$router.push({
        path: this.$route.query.tradmark ? "/hostingPlatforms/myRenewal" : "/personalCenter/myOrder"
      })
    },
  }
}
</script>
<style lang="less" scoped>
.paysuccess_wrap {
  min-height: calc(100vh - 85px);
  padding: 89px 100px 9px;
  box-sizing: border-box;
  background: #f2f2f2;
  .paysuccess_con {
    width: 100%;
    min-height: calc(100vh - 183px);
    background: #fff;
    padding: 160px 0 80px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    h5 {
      text-align: center;
      font-size: 28px;
      color: #243c5e;
      margin-bottom: 35px;
      i {
        display: inline-block;
        width: 46px;
        height: 46px;
        margin-right: 16px;
        vertical-align: middle;
        margin-top: -3px;

        img {
          display: block;
          width: 100%;
        }
      }
    }
    p {
      text-align: center;
      span {
        font-size: 16px;
        color: #333;
        padding: 0 20px;
        position: relative;
        cursor: pointer;
        &.active {
          color: @blue;
        }
        /*&:nth-child(1)::after {*/
        /*  content: "";*/
        /*  width: 1px;*/
        /*  height: 20px;*/
        /*  background: #d3d3d3;*/
        /*  position: absolute;*/
        /*  right: 0;*/
        /*  top: 50%;*/
        /*  margin-top: -10px;*/
        /*}*/
      }
    }
  }
}
</style>
